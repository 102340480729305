import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, updateDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { useAuthContext } from '../../components/hooks/useAuthContext';
import ValuesEditor from './ValuesEditor';
import useFetchSingleEntry from '../hooks/useFetchSingleEntry';

const EntryPage = () => {
    const { entryId } = useParams();
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const { entry, loading, error } = useFetchSingleEntry(user?.uid, entryId);

    const [editedDate, setEditedDate] = useState('');
    const [editedTime, setEditedTime] = useState('');
    const [imageError, setImageError] = useState(null);

    useEffect(() => {
        if (entry?.setAt) {
            setEditedDate(entry.setAt.toISOString().split('T')[0]);
            setEditedTime(entry.setAt.toTimeString().slice(0, 5));
        }
    }, [entry]);

    const updateEntry = async (updatedFields) => {
        if (!entry || !entry.id) {
            console.error('No entry to update');
            return;
        }
        try {
            await updateDoc(doc(db, `users/${user.uid}/entries/${entry.id}`), updatedFields);
        } catch (error) {
            console.error('Error updating entry:', error);
        }
    };

    const handleDateChange = (event) => {
        setEditedDate(event.target.value);
        updateSetAt(event.target.value, editedTime);
    };

    const handleTimeChange = (event) => {
        setEditedTime(event.target.value);
        updateSetAt(editedDate, event.target.value);
    };

    const updateSetAt = (date, time) => {
        const newSetAt = new Date(`${date}T${time}`);
        const myTimestamp = Timestamp.fromDate(newSetAt);
        updateEntry({ setAt: myTimestamp });
    };

    const handleDelete = async () => {
        if (!entry || !entry.id) {
            console.error('No entry to delete');
            return;
        }
        if (window.confirm('Are you sure you want to delete this entry?')) {
            try {
                await deleteDoc(doc(db, `users/${user.uid}/entries/${entry.id}`));
                navigate(-1);
            } catch (error) {
                console.error('Error deleting entry:', error);
                alert('Failed to delete entry.');
            }
        }
    };

    const handleImageError = () => {
        setImageError('Failed to load image');
        console.error('Image failed to load');
    };

    if (loading) {
        return <div className="text-center text-gray-800 p-4">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-red-600 p-4">Error: {error.message}</div>;
    }

    if (!entry) {
        return <div className="text-center text-gray-800 p-4">No entry found.</div>;
    }

    return (
        <div className="p-4 max-w-4xl mx-auto">
            <div className="flex justify-between items-center mb-4">
                <button 
                    onClick={() => navigate(-1)} 
                    className="bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                >
                    Back
                </button>
                <button 
                    onClick={handleDelete} 
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
                >
                    Delete
                </button>
            </div>
            
            <div className="bg-white rounded-lg shadow-lg px-6 py-5">
                <h1 className="text-2xl font-bold mb-6">{entry.type.charAt(0).toUpperCase() + entry.type.slice(1)} Entry Details</h1>
                
                {/* Image Display Section */}
                {entry.type === 'symptom' && (
                    <div className="mt-6 mb-8">
                        <h2 className="text-lg font-semibold mb-3">Reaction Photo</h2>
                        {entry.imageData ? (
                            <div className="relative w-full max-w-2xl mx-auto">
                                <img 
                                    src={entry.imageData.downloadUrl} 
                                    alt="Symptom reaction" 
                                    className="w-full h-auto rounded-lg shadow-md"
                                    onError={handleImageError}
                                />
                                {imageError && (
                                    <div className="absolute inset-0 flex items-center justify-center bg-gray-100 rounded-lg">
                                        <p className="text-red-500">{imageError}</p>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="text-center text-gray-500 py-8 bg-gray-50 rounded-lg">
                                No image uploaded for this entry
                            </div>
                        )}
                    </div>
                )}

                {/* Date and Time Section */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                    <label className="block">
                        <span className="text-gray-700 text-sm font-semibold mb-2 block">Date:</span>
                        <input
                            type="date"
                            value={editedDate}
                            onChange={handleDateChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700 text-sm font-semibold mb-2 block">Time:</span>
                        <input
                            type="time"
                            value={editedTime}
                            onChange={handleTimeChange}
                            className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
                        />
                    </label>
                </div>

                {/* Values Editor Section */}
                <div className="mt-6">
                    <ValuesEditor entry={entry} onUpdate={(newValues) => updateEntry({ values: newValues })} />
                </div>
            </div>
        </div>
    );
};

export default EntryPage;