import React, { useState } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { Camera, X } from 'lucide-react';

const ImageUpload = ({ 
    userId, 
    entryId, 
    onImageUpload, 
    existingImageUrl = null, 
    existingStoragePath = null 
}) => {
    // State management for the component
    const [uploading, setUploading] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(existingImageUrl);
    const [error, setError] = useState(null);
    const [storagePath, setStoragePath] = useState(existingStoragePath);

    // Handle cleaning up old images when replacing them
    const handleCleanupOldImage = async () => {
        if (storagePath) {
            try {
                const storage = getStorage();
                const oldImageRef = ref(storage, storagePath);
                await deleteObject(oldImageRef);
                console.log('Old image deleted successfully');
            } catch (error) {
                console.error('Error deleting old image:', error);
                // Continue with upload even if cleanup fails
            }
        }
    };

    // Main function to handle image capture/upload
    const handleImageCapture = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        // File validation
        if (!file.type.startsWith('image/')) {
            setError('Please select an image file');
            return;
        }

        // 5MB size limit
        if (file.size > 5 * 1024 * 1024) {
            setError('Image must be less than 5MB');
            return;
        }

        try {
            setUploading(true);
            setError(null);

            // Create a preview for immediate user feedback
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewUrl(reader.result);
            };
            reader.readAsDataURL(file);

            // Clean up any existing image first
            await handleCleanupOldImage();

            // Prepare the storage path and reference
            const storage = getStorage();
            const timestamp = Date.now();
            const fileExtension = file.name.split('.').pop();
            // Create a unique path for this image using userId and timestamp
            const newStoragePath = `users/${userId}/reaction-images/${timestamp}.${fileExtension}`;
            const storageRef = ref(storage, newStoragePath);
            
            // Upload the new image
            const snapshot = await uploadBytes(storageRef, file);
            console.log('Image uploaded successfully:', snapshot);

            // Get the public URL for the image
            const downloadUrl = await getDownloadURL(snapshot.ref);
            console.log('Download URL generated:', downloadUrl);

            // Update state and notify parent component
            setStoragePath(newStoragePath);
            onImageUpload({
                downloadUrl,
                storagePath: newStoragePath
            });

        } catch (err) {
            console.error('Error uploading image:', err);
            setError('Failed to upload image. Please try again.');
            // Reset preview if upload fails
            setPreviewUrl(existingImageUrl);
        } finally {
            setUploading(false);
        }
    };

    // Handle removing the current image
    const handleRemoveImage = async () => {
        try {
            await handleCleanupOldImage();
            setPreviewUrl(null);
            setStoragePath(null);
            onImageUpload(null);
        } catch (error) {
            console.error('Error removing image:', error);
            setError('Failed to remove image. Please try again.');
        }
    };

    return (
        <div className="w-full">
            <div className="flex flex-col items-center justify-center w-full">
                {previewUrl ? (
                    // Display image preview with controls
                    <div className="relative w-full max-w-md mb-4">
                        <img 
                            src={previewUrl} 
                            alt="Reaction" 
                            className="w-full h-auto rounded-lg shadow-lg"
                        />
                        <div className="absolute bottom-2 right-2 flex space-x-2">
                            {/* Button to remove current image */}
                            <button
                                type="button"
                                onClick={handleRemoveImage}
                                className="bg-red-600 hover:bg-red-700 text-white p-2 rounded-full shadow-lg"
                            >
                                <X className="h-6 w-6" />
                            </button>
                            {/* Button to replace current image */}
                            <label className="bg-purple-600 hover:bg-purple-700 text-white p-2 rounded-full cursor-pointer shadow-lg">
                                <Camera className="h-6 w-6" />
                                <input
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    onChange={handleImageCapture}
                                    className="hidden"
                                />
                            </label>
                        </div>
                    </div>
                ) : (
                    // Upload button when no image is present
                    <label className="flex flex-col items-center justify-center w-full h-40 border-2 border-purple-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                            <Camera className="h-12 w-12 text-purple-500 mb-2" />
                            <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">Take a photo</span> or click to upload
                            </p>
                            <p className="text-xs text-gray-500">
                                Maximum file size: 5MB
                            </p>
                        </div>
                        <input
                            type="file"
                            accept="image/*"
                            capture="environment"
                            onChange={handleImageCapture}
                            className="hidden"
                        />
                    </label>
                )}

                {/* Loading and error states */}
                {uploading && (
                    <div className="mt-2 text-sm text-gray-600">
                        Uploading image...
                    </div>
                )}
                {error && (
                    <div className="mt-2 text-sm text-red-600">
                        {error}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUpload;